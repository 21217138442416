import React from 'react'

import Seo from '../components/seo'
import Layout from '../components/layout'

class OemIndex extends React.Component {
  render() {
    return (
      <Layout location={this.props.location}>
        <Seo title="Oem" />
        <div align="center">
            
            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3239.795636480059!2d139.78190091561243!3d35.70664633618436!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x60188ebb5c060d99%3A0xf674f014586d12d5!2z44CSMTExLTAwNTYg5p2x5Lqs6YO95Y-w5p2x5Yy65bCP5bO277yS5LiB55uu77yR77yY4oiS77yR77yUIOOCv-ODnue-juWuueWMluWtpg!5e0!3m2!1sja!2sjp!4v1664369669310!5m2!1sja!2sjp" width="600" height="450"  allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
        </div>
        <hr/>
        <div align="center">
            <div>
                <p>本店：東京都台東区小島2-18-14<br />
                    Tel：<a href="tel:0338518707">03-3851-8707</a></p>
            </div>
            <div>
                <p>●最寄駅<br />
                    大江戸線、つくばエクスプレス：新御徒町駅<br />
                    地上A4出口より徒歩３０秒</p>
            </div>
            <div>
                <p>●営業時間<br />
                    月　11:00-18:00<br />
                    水～土　11:00-19:00<br />
                    <br />
                    火曜日と日曜日、祝日は定休日です。<br />
                    GWや年末年始もお休みします。<br />
                    何かございましたらお問い合わせください。</p>
            </div>
        </div>
      </Layout>
    )
  }
}

export default OemIndex

